
.paper-wrapper {
    width: 100%;
    height: 100%;
    /*background: #fff;*/
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .paper-container {
        width: 1200px;
        margin: 0 auto;
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
        .paper-title {
            text-align: center;
            color: #409eff;
            font-size: 24px;
            font-weight: 500;
        }
        .paper-content {
            margin-top: 20px;
            .question-type-item {
                margin-bottom: 20px;
                .question-type-title {
                    .title {
                        color: #333;
                        font-size: 18px;
                        font-weight: 500;
                    }
                    .subtitle {
                        font-size: 16px;
                        color: #666;
                    }
                }
                .subject-item {
                    margin-top: 10px;
                    padding-bottom: 20px;
                    .subject-title {
                        color: #333;
                        font-size: 16px;
                        font-weight: 500;
                        .title {
                            ::v-deep p {
                                margin: 0;
                                display: inline;
                            }
                        }
                        .score {
                            color: #666;
                            opacity: 0.7;
                            margin: 0 10px;
                        }
                    }
                    .subject-answer {
                        display: block;
                        margin-top: 15px;
                        padding-left: 20px;
                        .answer-item {
                            margin-top: 6px;
                            display: flex;
                            ::v-deep .el-radio__label {
                                width: 1%;
                                flex: 1;
                                white-space: normal;
                                line-height: 1.3;
                            }
                        }
                        .textarea-box {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
